




import React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import { Box, Button, Container, Grid, Paper, Stack, Typography } from "@mui/material";
import Debug from "../components/Debug";
import useSiteMetadata from "../components/SiteMetadata";
import Markdown from "../components/Markdown";
import ButtonBackToMainPage from "../components/ButtonBackToMainPage";


const data = `
# Polityka plików cookies oraz Web Storage

Strona internetowa używa pliki cookies oraz podobne technologie, w tym Web Storage.

Pliki cookies („ciasteczka”) to małe pliki tekstowe, który mogą być umieszczone na Twoim Urządzeniu. Kiedy Twoja przeglądarka internetowa kontaktuje się z naszym serwerem, przesyła do niego także wcześniej zapisane na Twoim urządzeniu pliki cookies. Dzięki temu możemy rozpoznać, że dane żądanie pochodzi z Twojej przeglądarki. Na tej podstawie możemy spersonalizować dane, które nasz serwer prześle do Twojego urządzenia. Treść plików cookies może być pobrana i odczytana jedynie przez serwer, który utworzył dany plik.

Web Storage to magazyn danych w Twojej przeglądarce umożliwiający stronom i aplikacjom internetowym możliwość przechowywania danych lokalnie w przeglądarce internetowej Użytkownika. Dzięki temu część informacji może zostać trwale zapisana w pamięci Twojej przeglądarki i nie ma konieczności ponownego pobierania ich z serwera, co znacznie skraca czas obsługi żądań przesyłanych do naszego serwera.  Web Storage jest standaryzowany przez konsorcjum World Wide Web Consortium (W3C) i WHATWG i jest obsługiwany przez wszystkie główne przeglądarki.

Strona internetowa udostępnia metody, aby Użytkownik mógł w sposób dobrowolny, konkretny, świadomy i jednoznaczny wyrazić bądź nie wyrazić zgodę na umieszczenie na jego umieszczeniu plików cookies lub zastosowania Web Storage.

W razie jakichkolwiek pytań związanych z wykorzystywaniem plików cookies i Web Storage w każdej chwili możesz skontaktować się z nami pisząc wiadomość na adres e-mail podany w Regulaminie.

# Rodzaje plików cookies

Strona internetowa stosuje pliki cookies własne oraz pliki cookies podmiotów trzecich. Pliki cookies własne są wygenerowane przez nasz serwer i są związane ze świadczeniem przez nas Usług drogą elektroniczną za pośrednictwem Strony internetowej. Każdorazowo kiedy Twoja przeglądarka wysyła zapytanie do naszego serwera, zapytanie to ma dołączone do siebie wygenerowane przez nas pliki cookies. Pliki cookies podmiotów trzecich są wygenerowane przez niektórych z naszych usługodawców. Korzystając z niektórych funkcjonalności Strony internetowej Twoja przeglądarka wysyła zapytania do serwera wspomnianego podmiotu trzeciego, a odpowiedź serwera zawiera pliki cookies, które zostają zapisane w pamięci Twojej przeglądarki. W przypadku ponownego żądania będzie ono zawierało zapisane wcześniej pliki cookies, dzięki czemu serwer podmiotu trzeciego rozpozna, że dane żądanie zostało wysłane przez Ciebie.

Na Stronie internetowej wykorzystujemy dwa typy plików cookies uwzględniając ich czas przechowywania na Urządzeniu: 

Pliki cookies sesyjne – są przechowywane na Twoim Urządzeniu na czas sesji i pozwalają Użytkownikowi na pozostanie zalogowanym, kiedy przełącza poszczególne podstrony Strony internetowej. Pliki te są usuwane kiedy zamykasz przeglądarkę internetową.

Pliki cookies trwałe - są przechowywane na Twoim Urządzeniu aż do momentu ich usunięcia. Oznacza to, że wyłączenie przeglądarki lub wyłączenie Urządzenia nie powoduje ich usunięcia z Twojego Urządzenia.

Pliki cookies zawierają w sobie treści tekstowe i są jedynie nośnikiem informacji – nie są w stanie wykonać jakiejkolwiek czynności, m.in. uruchomienia kodu programistycznego. Oznacza to, że pliki cookies nie pozwalają na pobieranie jakichkolwiek danych osobowych ani żadnych poufnych informacji zapisanych w Twoim Urządzeniu.

Na naszej Strony internetowej wykorzystujemy dwa typy przechowywania informacji w Web Storage: przechowywanie sesji i przechowywanie lokalne, które zachowują się analogicznie do plików cookies sesyjnych i plików cookies trwałych.

Na naszej Strony internetowej wykorzystujemy następujące rodzaje plików cookies oraz informacji zapisanych w Web Storage:

* niezbędne - są konieczne do prawidłowego działania Strony internetowej,
* funkcjonalne - poprawiają funkcjonalność Strony internetowej; zapamiętują wybrane przez Ciebie ustawienia, dzięki czemu możemy dostarczyć Ci bardziej spersonalizowane treści i usługi, 
* wydajnościowe - zbierają informacje oraz dane statystyczne o tym w jaki sposób Użytkownicy korzystają z naszej Strony internetowej, co pozwala nam poprawiać jakość naszych usług
* służące do zapewnienia bezpieczeństwa – stanowią jeden z elementów ochrony Strony internetowej przed cyberatakami.

Pliki cookies zazwyczaj zawierają nazwę domeny strony internetowej, z której pochodzą, planowany czas przechowywania ich na Twoim Urządzeniu oraz przypisaną im wartość.

Podczas Twojej pierwszej wizyty na Stronie internetowej wyświetlona zostanie informacja na temat stosowania plików Cookies w formie alertu pop-up.

# Niezbędne pliki cookies

Strona internetowa korzysta z plików cookie, która uznawane są za pliki niezbędne i Strona internetowa nie może działać bez nich prawidłowo. Korzystamy z tych plików cookie, ponieważ uważamy, że umożliwienie prawidłowego działania niniejszej witryny leży w uzasadnionym interesie naszym i naszych użytkowników. 

Zwykle używamy tych plików do realizacji konkretnej funkcji witryny, w tym:

* zapamiętania zaakceptowania przez użytkownika treści Polityki Prywatności, Regulaminu lub Politykie plików cookies.

Niezbędne pliki cookie nie zbierają i nie wykorzystują danych osobowych. Niezbędne pliki cookie to zwykle pliki cookie sesji. 

# Cele, w jakich wykorzystujemy pliki Cookies oraz Web Storage

Jako Administrator wykorzystujemy pliki cookies własne lub Web Storage w następujących celach:

* zapewnienie pełnej funkcjonalności Strony internetowej oraz ich konfiguracja; mechanizmy te pozwalają na: 
    * dostosowanie zawartości przeglądanych przez Ciebie stron internetowych do Twoich preferencji,
    * dostosowanie Strony internetowej do specyfikacji technicznej Twojego Urządzenia,
    * zapamiętanie wybranych przez Ciebie ustawień i dokonanie personalizacji Twojego interfejsu,
    * poprawne funkcjonowanie usług naszych podwykonawców, 
* uwierzytelnianie zarejestrowanego Użytkownika oraz zapewnienie ciągłości sesji: 
* przeprowadzenie anonimowej analizy użytkowania Strony internetowej – pliki cookies oraz Web Storage pomagają nam zrozumieć, w jaki sposób Użytkownicy korzystają ze Strony internetowej. W ramach tych działań mogą być gromadzone dane na temat:
    * obszarów Strony internetowej, które odwiedzasz, 
    * czasu jaki spędzasz korzystając z poszczególnych funkcjonalności
    * błędów Strony internetowej, które wystąpiły podczas przeglądania jej na Urządzeniu Użytkownika,
* zapewnienie bezpieczeństwa Strony internetowej i ochrona przed cyberatakami.

Korzystając z przeglądarki internetowej masz możliwość zablokować bądź ograniczyć możliwość zastosowania przez Stronę internetową plików cookies oraz Web Storage. Przeglądanie Strony internetowej będzie dalej możliwe, jednak niedostępne będą poszczególne funkcje, które do swojego funkcjonowania wymagają powyższych mechanizmów (m.in. rejestracja konta oraz utrzymywanie sesji).

Możesz w każdym czasie wycofać swoją zgodę bądź udzielić zgody na stosowanie przez nas plików cookies oraz Web Storage. Formularz zgody znajdziesz na podstronie ustawień Użytkownika.

Możesz w każdym czasie usunąć dotychczas zgromadzone pliki cookies oraz informacje zapisane w Web Storage, korzystając z ustawień przeglądarki internetowej.

# Pliki Cookies własne lub Web Storage wykorzystywane na Stronie internetowej

consentToPolicies - Plik funkcjonalny. Przechowuje informację, czy Użytkownikow wyraził zgodę na Regulamin, Politykę prywatności oraz Politykę ciasteczek. Przechowywanie lokalne. Czas przechowywania - bezterminowo, aż do usunięcia.
`

// wasTourHintSeen - Plik funkcjonalny. Przechowuje informację, czy Użytkownikowi została przedstawiona informacja o możliwości włączenia trybu samouczka, przedstawiającego funkcjonalności Aplikacji. Przechowywanie lokalne. Czas przechowywania - bezterminowo, aż do usunięcia.




export const IndexPage = ({

}) => {


    const { tel,
        linkgooglemap,
        address } = useSiteMetadata();


    return (
        <Layout variant="regulamin">

            <Container maxWidth="sm" sx={{
               
                position: "relative"
            }}>

                {/* <Typography variant="h1">Polityka prywatności</Typography> */}
                <ButtonBackToMainPage />




                <Markdown>{data}</Markdown>


            </Container>
        </Layout>

    );
};




export default IndexPage;

export const Head = () => {

    const { tel,
        linkgooglemap,
        address,
        title,
        description,
        siteUrl
    } = useSiteMetadata();

    return (<>
        {/* https://www.searchenginejournal.com/important-tags-seo/156440/ */}
        <title>{title}</title>
        <meta name="description" content={description} />
        {/* <meta name="robots" content="nofollow"> */}

        {/* <link rel="canonical" href={siteUrl} /> */}

        <meta property="og:title" content={title} />

        <meta property="og:description" content={description} />

        <meta property="og:image" content={`${siteUrl}/img/map.jpg`} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {/* <meta charset="UTF-8" /> */}


    </>


    )
}


